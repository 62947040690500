@import "../../../../src/Colors/Colors.scss";

.comp-menu{
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 20px;
	padding: 0 15px;
	transform: translateX(-1rem);
	max-width: 700px;

	> li {
		flex: 1;
		margin: 0;
		max-width: 220px;

		a {
			display: block;
			color: inherit;
			text-decoration: none;
			padding: 15px 1em 15px 0;
			color: $prussian90;
			white-space: nowrap;

			&::before {
				content: "# ";
			}

			&:hover{
				text-decoration: underline;
			}
		}
	}
}

.demo-alert {
	padding: 1em 2em;
    margin: 0.5em -2em;
	background-color: #FFFAA8;
	color: #000;

	& svg {
		position: absolute;
		height: 1.75em;
		right: 2em;
	}
	
	& a:link, a:visited, a:active, a:hover {
		color: #000;
		text-decoration: underline;
	}
}