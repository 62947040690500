.demo-markdown-viewer {
	code {
		.token {
			&.atrule,
			&.keyword,
			&.attr-value,
			&.string,
			&.selector,
			&.attr-name,
			&.char,
			&.builtin,
			&.inserted,
			&.comment,
			&.prolog,
			&.doctype,
			&.cdata,
			&.punctuation,
			&.function,
			&.class-name,
			&.regex,
			&.important,
			&.variable,
			&.property,
			&.tag,
			&.boolean,
			&.number,
			&.constant,
			&.symbol,
			&.operator,
			&.deleted {
				color: inherit;
				background: transparent;
			}
		}
	}
}

.demo-container {
	max-width: 750px;
}