@import "../../../../src/Colors/Colors.scss";

.demo-tags {
	.demo-tag {
		margin-right: 0.25em;
	}
}

.demo-tag,
a.demo-tag {
	display: inline-block;
	padding: 0 0.25em;
	background-color: $prussian90;
	color: #fff;
	text-decoration: none;
	border-radius: 5px;
}