.TypeTable-Container{
	margin-top: 25px;
	padding: 0 15px;

	& table{
		width:100%;
		max-width:100%;
		margin-bottom:20px;
		box-sizing:border-box;
		border-spacing:0;
		border-collapse:collapse;
		> *{
		  box-sizing:border-box;
		}
		> caption{
		  padding:8px 0;
		  color: rgb(155,155,155);
		  text-align:left;
		}
		> thead{
		  th{
			border-bottom:2px solid rgb(206,213,230);
			font-size:14px;
			padding:28px 16px 8px 16px;
			vertical-align:bottom;
			line-height:1.4;
			text-align:left;
			white-space:nowrap;
		  }
		}
		> tbody{
		  th{
			font-weight:bold;
		  }
		  th, td{
			text-align:left;
			padding:16px;
			line-height:1.4;
			vertical-align:top;
			border-top:1px solid rgb(204,204,204);
		  }
		  > tr:first-child{
			> th, > td{
			  border-top:0;
			}
		  }
		}
		&.striped{
		  tbody{
			> tr:nth-child(2n+1){
			  background-color: rgb(249,249,249);
			}
		  }
		}
		&.framed{
		  border:1px solid rgb(204,204,204);
		}
		&.bordered{
		  border:1px solid rgb(204,204,204);
		  > thead{
			th{
			  border-left:1px solid rgb(204,204,204);
			  &:first-child{
				border-left:0;
			  }
			}
		  }
		  > tbody{
			th, td{
			  border-left:1px solid rgb(204,204,204);
			  &:first-child{
				border-left:0;
			  }
			}
		  }
		}
		&.condensed{
		  > thead{
			th{
			  padding:16px 5px 5px 5px;
			}
		  }
		  > tbody{
			th, td{
			  padding:5px;
			}
		  }
		}
		&.hover-highlight{
		  overflow:hidden;
		  > tbody{
			> tr:hover{
			  background-color: rgb(242,242,242);
			}
		  }
		}
		&.hover-col-highlight{
		  overflow:hidden;
		  > tbody{
			td:hover, th:hover{
			  position:relative;
			  &::after{
				content: "";
				position:absolute;
				background-color: rgb(242,242,242);
				left:0;
				top:-15000px;
				height:30000px;
				width:100%;
				z-index:-1;
			  }
			}
		  }
		}
	  }

	  & table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
		box-sizing: border-box;
		border-spacing: 0;
		border-collapse: collapse;
	}


	.TypeTable-extenders{
		color: #666;
		font-size: 14px;
	}

	h3{
		margin: 0;
	}

	.colwidth-s{
		max-width: 166px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.colwidth-max {
		max-width: 450px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	thead th {
		border-bottom: 2px solid #24365c;
		font-size: 14px;
		padding: 28px 16px 8px 16px;
		vertical-align: bottom;
		line-height: 1.4;
		text-align: left;
		white-space: nowrap;
	}

	tbody tr {
		border-bottom: 1px solid #d4d4d4;
	}

	td{
		font-size: 14px;
	}
	td[colspan]{
		font-weight: bold;
	}
	code{
		font-size: 12px;
	}

	+ .TypeTable-Container{
		margin-top: 45px;
	}
}