@import "../../../../src/Colors/Colors.scss";

.demo-nav {
	$root: &;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	z-index: 998;
	box-sizing: border-box;
	color: #fff;
	overflow: hidden;

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: rgba(255,255,255,0.5);
		opacity: 1; /* Firefox */
	  }
	  
	  :-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: rgba(255,255,255,0.5);
	  }
	  
	  ::-ms-input-placeholder { /* Microsoft Edge */
		color: rgba(255,255,255,0.5);
	  }

	&--expanded {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex: 0 0 320px;
		padding: 3em 2em 2em;
		background-color: $prussian90;
		overflow: auto;
    	position: fixed;
    	min-width: 300px;

		& #{$root}__content,
		div.demo-easter-egg-fs {
			display: block;
		}
	}

	&__content,
	.demo-easter-egg-fs {
		display: none;
	}

	&__content {
		input {
			display: block;
			width: 100%;
			font-size: 1em;
			background-color: transparent;
			color: #fff;
			border: none;
			border-bottom: 1px solid #fff;
			margin-bottom: 0.5em;
			padding: 4px;
		}
	}

	&__input-container {
		position: relative;

		& input {
			margin-bottom: 1.5em;
		}
	}

	&__item {
		&-desc {
			display: flex;
			margin-bottom: 0.5em;
		}

		&-label {
			color: #fff;

			&--no-children {
				color: rgba(255,255,255, 0.65);
			}
		}

		&-toggle {
			border: none;
			background-color: transparent;
			color: #fff;
			padding: 2px 0.5em 0 2px;

			& svg {
				transition: transform 0.2s ease-in-out;
				width: 1em;
				height: 1em;
			}
		}

		& ul {
			opacity: 0;
			display: none;
			transition: all 0.2s ease-in-out;
			margin-bottom: 1em;

			& a {
				display: flex;
				align-items: center;
				padding: 0.25em 1em 0.25em 1em;
				transform: translateX(-1em);
				width: 100%;

				& > span {
					flex-grow: 1;
				}
			}
		}

		&--open {
			& ul {
				display: block;
				opacity: 0;
				animation: fadeAndAppear 0.2s ease-in-out;
				animation-fill-mode: forwards;
			}

			& #{$root}__item-toggle {
				& svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	button {
		font-size: 1em;

		&.demo-nav-link {
			padding: 0.25em 1em 0.25em 0;
			text-align: left;
			width: 100%;
			
			&:focus {
				outline: 1px dotted #fff;
			}
		}
	}

	a {
		color: #fff;
		text-decoration: none;
		display: flex;
		align-items: center;
		padding: 0.25em 1em 0.25em 0;
		width: 100%;

		&#{$root}__bottom {
			font-size: 0.8em;
			color: rgba(255,255,255, 0.5);
		}
	}

	a:focus {
		outline: 1px dotted #fff;
	}
	

	& ul {
		margin-top: 0;
		list-style: none;
		padding-left: 1em;
	}

	& ul#{$root}__root-list {
		margin-bottom: 1em;
		padding: 0;
	}
}

.demo-menu-button {
	position: absolute;
	top: 1rem;
	left: 2rem;
	z-index: 999;
	transform: rotate(90deg);
	color: #333;
	background-color: transparent;
	border: none;
	font-size: 1.2em;
	transition: color 0.15s ease-in-out;

	&__light {
		color: #fff;
	}
}

h1 {
	margin: 0;
}

.demo-nav__version {
	display: flex;
	padding-bottom: 30px;
	padding-top: 10px;
	a {
		width: 60px;
		padding: 2px 20px;
		border: 2px solid $slateGrey80;
		width: fit-content;
	}
}

.demo-nav-background {
	position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.demo-nav-link {
	border: none;
	background-color: transparent;
	position: relative;

	&--active {
		font-weight: bold;
	}
}

.demo-tooltip {
	position: absolute;
	font-size: 0.8em;
	top: 100%;
	top: calc(100% + 0.25em);
	white-space: nowrap;
}

@keyframes fadeAndAppear {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}