header.comp-header{
	box-sizing: border-box;

	h1{
		margin: 0;
		font-weight: normal;
		font-size: 42px;
		
		& .demo-beta-badge {
			border-color: #000;
		}
	}
}
