@import "../../../../src/Colors/Colors.scss";

.demo-code-viewer{
	border: 3px solid $prussian90;
	border-top: none;
	overflow-x: auto;
	
	pre{
		margin: 0;
		padding: 1em;
	}
}