@import '../../src/styles/fonts/_avenir.scss';
@import '../../src/styles/fonts/_broaderview.scss';

@include fontface-avenir();
@include fontface-broaderView();

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	padding: 0;
	margin: 0;
	font-size: 16px;
	line-height: 1.6;
}

body.font-avenir--loaded {
	font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*

img {
	max-width: 100%;
}

h1 {
	font-size: 2.5rem;
	margin-top: 0;
}

h2 {
	font-size: 1.75rem;
}

h3 {
	font-size: 1.5rem;
}

h4 {
	font-size: 1.25rem;
}

h1, h2, h3, h4 {
	font-weight: normal;
	margin: 1em 0 0.2em 0;
}

*, *:after, *:before {
	box-sizing: border-box;
}

p {
	max-width: 700px;
	margin: 0 0 1em 0;
}

ul, ol  {
	li {
		p {
			margin-bottom: 0.75em;
		}
	}
}

pre {
	color: #fff;
	display: block;
	max-width: 100%;
	overflow-x: auto;
	background-color: #1a1423;

	& code {
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    	line-height: 1.3;
		display: block;
		color: inherit;
		padding: 0.5em;
		width: 100%;
		min-width: 100%;
		background-color: #1a1423;
	}
}

code {
	display: inline-block;
	line-height: 1;
}

p, ul, ol, span, h1, h2, h3, h4, table {
	code {
		background-color: #f5f2f0;
	}

	.token.operator,
	.token.entity,
	.token.url,
	.language-css .token.string,
	.style .token.string {
		color: #9a6e3a;
		background: hsla(0, 0%, 100%, .5);
	}

	.token {
		&.atrule,
		&.keyword,
		&.attr-value,
		&.string,
		&.selector,
		&.attr-name,
		&.char,
		&.builtin,
		&.inserted,
		&.comment,
		&.prolog,
		&.doctype,
		&.cdata,
		&.punctuation,
		&.function,
		&.class-name,
		&.regex,
		&.important,
		&.variable,
		&.property,
		&.tag,
		&.boolean,
		&.number,
		&.constant,
		&.symbol,
		&.deleted {
			color: inherit;
		}
	}
}

table {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 1rem;
	
	& td,
	& th {
		text-align: left;
		vertical-align: top;
		border-bottom: 2px solid #dee2e6;
		padding: .25rem;

		& code {
			white-space: nowrap
		}

		& pre code {
			white-space: normal;
		}
	}
}
*/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.demo-beta-badge {
	padding: 0 0.25em;
	border: 1.75px solid #fff;
	letter-spacing: 1px;
	font-weight: 600;
	border-radius: 4px;
	font-size: 0.8em;
	flex-grow: 0 !important;

	&::after {
		content: "BETA"
	}
}