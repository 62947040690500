@import "../../../../src/Colors/Colors.scss";

.demo-example-viewer {
  margin-left: -2em;
	margin-right: -2em;
	padding: 2em;

	&--overflow {
		overflow: hidden;
	}
}

.demo-example-preview {
	$root: &;
	position: relative;
	margin-bottom: 1em;

	&--fullscreen {
		position: fixed;
		background-color: #fff;
		top: 0;
		left: 0;
		padding: 2em 0;
		height: 100vh;
		width: 100vw;
		z-index: 1000000;
		overflow: auto;

		& #{$root} {
			&-button {
				top: 5px;
				right: 5px;
			}
		}
	}

	&-button {
		position: absolute;
		bottom: 100%;
		right: 0;
		line-height: 0.8;
		border: none;
		background-color: transparent;
		padding: 4px;
		width: 26px;
		height: 26px;
	
		& svg {
			width: 18px;
			height: auto;
		}
	}
}

.demo-example-nav {
	list-style: none;
	padding: 0;
	margin-bottom: 0;

	li {
		display: inline-block;
	}

	a {
		display: block;
		padding: 0.5em;
		text-decoration: none;
		position: relative;

		&.active {
			background-color: #333;
			color: #fff;

			&::after {
				content: "";
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -7.5px;
				display: block;
				width: 0; 
				height: 0; 
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 8px solid #333;
			}
		}
	}
}

.demo-toggle-example {
	display: block;
	width: 100%;
	background-color: $prussian90;
    color: #fff;
    border: none;
	padding: 1em;
	transition: background-color 0.2s ease-in-out;
	
	&:hover {
		cursor: pointer;
	}
}