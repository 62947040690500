.demo-easter-egg {
	position: fixed;
	top: 0;
	left: 0;
}

.demo-easter-egg-fs {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: -1;
}