@import "../Tags/Tags.scss";

.demo-tag-page {
	input {
		display: block;
		width: 100%;
		max-width: 320px;
		font-size: 1em;
		background-color: transparent;
		border: none;
		border-bottom: 2px solid #b3b3b3;
		margin-bottom: 1em;
		padding: 4px;
	}
}